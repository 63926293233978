import { FC, HTMLAttributes, JSX } from 'react';
import block from 'utils/bem-css-module';

import style from './heading.scss';

const b = block(style);

type Props = {
    level: 1 | 2 | 3 | 4 | 5 | 6;
    size: 'xs' | 's' | 'sm' | 'm' | 'l' | 'xl' | 'xxl';
}
export const Heading: FC<Props & HTMLAttributes<HTMLElement>> = ({
	level,
	size, 
	className,
	children,
	...rest
}) => {
	const HeaderTag = `h${level}` as keyof JSX.IntrinsicElements;

	return (
		<>
			{/** @ts-ignore **/}
			<HeaderTag className={b({ size }).mix(className)} {...rest}>
				{children}
			</HeaderTag>
		</>
	);
};
