import { addDocument } from 'utils/document';

import { TYPE } from 'constants/action-types';

import { DocumentStatus } from 'types/document';
import { State } from 'types/state';


export default function (state: State['documents'] = null, action): State['documents'] {
	switch (action.type) {
		case TYPE.DOCUMENTS.RECEIVE: {
			return [
				...(state || []).filter(({ status }) => !Object.values(DocumentStatus).includes(status as DocumentStatus)),
				...action.data.results,
			];
		}
		case TYPE.DOCUMENTS.ADD: {
			return addDocument(state, action.data);
		}
		case TYPE.DOCUMENTS.DELETE_SCAN_DOCUMENT: {
			if (state === null) {
				return null;
			}
			return [...state.filter((document) => (
				!('operation_id' in document) || document.operation_id !== action.operation_id
			))];
		}
		default: {
			return state;
		}
	}
}
