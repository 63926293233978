import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDocuments } from 'actions/documents';
import { getPoints } from 'actions/points';
import { getFakeUser, getUser } from 'actions/user';

import { State } from 'types/state';

export const useGetRootData = () => {
	const dispatch = useDispatch();
	const user = useSelector((state: State) => state.user);
	const isUserExist = Boolean(user);
	
	useEffect(() => {
		dispatch(getPoints());
		if (!isAuthorised && !/\/(login|regist|recovery)?\/.*/.test(window.location.pathname)) {
			dispatch(getFakeUser());
			return;
		}
		if (!isUserExist) {
			dispatch(getUser());
		}
		dispatch(getDocuments());
	}, []);
};
