import { FC, HTMLAttributes, useRef } from 'react';
import block from 'utils/bem-css-module';

import { ModalCloseButton } from './modal-close-button';

import style from './modal.scss';

type Props = {
    className?: string;
    close?: () => void;
    id: string;
	isOpen: boolean;
}

const b = block(style);

export const ModalHeader: FC<Props & HTMLAttributes<HTMLElement>> = ({
	className = '',
	close,
	id,
	children,
	isOpen,
	...rest
}) => {
	const headerId = `${id}-header`;
	const closeButton = useRef<HTMLButtonElement>(null);

	return (
		<header className={b('header').mix(className)} {...rest}>
			<h1 className={b('title')} id={headerId}>
				{children}
			</h1>
			{close && <ModalCloseButton onClick={close} ref={closeButton} isOpen={isOpen} /> }
		</header>
           
	);
};
