import { TYPE } from 'constants/action-types';

export default function(state = null, action) {
	switch (action.type) {
		case TYPE.POINTS.RECEIVE: {
			return action.data;
		}
		default: {
			return state;
		}
	}
}
