import { TYPE } from '../constants/action-types';

export function conversationMessages(state = {}, action: any) {
	switch (action.type) {
		case TYPE.CONVERSATION_MESSAGES.SET: {
			return {
				...state || {},
				[action.pk]: action.messages,
			};
		}
		case TYPE.CONVERSATION_MESSAGES.SET_ONE: {
			const pk: number = action.message.msg_conversation;
			return {
				...state || {},
				[pk]: [...state[pk], action.message],
			};
		}
		default: {
			return state;
		}
	}
}
