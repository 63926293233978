import { TYPE } from 'constants/action-types';

import { State } from 'types/state';


const DEFAULT_STATE: State['print'] = {
	options: {},
};

export default function (state: State['print'] = DEFAULT_STATE, action) {
	switch (action.type) {
		case TYPE.PRINT.CHANGE_OPTIONS: {
			return {
				...state,
				...action.options,
			};
		}
		default: {
			return state;
		}
	}
}
