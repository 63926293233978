export type Coords = {
	longitude: number;
	latitude: number;
}

export type GeoData = {
	coords: Coords;
	accuracy: number;
}

export type Status = {
	pk: number;
	message: string;
}

export type BombSettings = {
	x: number;
	y: number;
}

export type EmptyObject = Record<string, never>;

export enum Environment {
	development= 'development',
	testStand = 'test-stand',
	production = 'production',
}


export type OpenHours = {
	'Воскресенье'?: string;
	'Вторник'?: string;
	'Понедельник'?: string;
	'Пятница'?: string;
	'Среда'?: string;
	'Суббота'?: string;
	'Четверг'?: string;
}
