import { AnchorHTMLAttributes, FC, ReactNode } from 'react';
import block from 'utils/bem-css-module';

import style from './link.scss';


type Props = {
	children: ReactNode;
	url: string;
	className?: string;
	size?: 'xs' | 's' | 'sm' | 'm' | 'l' | 'xl';
}

const b = block(style);

export const Link: FC<Props & AnchorHTMLAttributes<HTMLElement>> =({
	children,
	url,
	size,
	className = '',
	...rest
}) => {
	return (
		<a
			href={url}
			target='_blank'
			rel='noopener noreferrer'
			className={b({ size }).mix(className)}
			{...rest}
		>
			{children}
		</a>
	);
};
