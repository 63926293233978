import { MOBILE_WIDTH } from 'constants/common';

export class StringMixer extends String {
	private content: string;

	constructor(initialContent: string) {
		super(initialContent);
		this.content = initialContent;
	}

	// Method to mix (concatenate) new content (string, undefined, or array of strings)
	mix(newContent: string | undefined | Array<string | undefined>) {
		if (Array.isArray(newContent)) {
			newContent.forEach(content => {
				if (content === undefined || content === '') {
					return;
				}
				this.content += ' ' + content;
			});
		} else if (newContent !== undefined) {
			this.content += ' ' + newContent;
		}
		return this.content;
	}

	toString(): string {
		return this.content;
	}
}

export function asyncTimeout(ms: number) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export function choosePluralForm(number: number, wordForms: [string, string, string]): string {
	if (number % 10 === 1 && number % 100 !== 11) {
		return wordForms[0]; // Единственное число
	} else if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)) {
		return wordForms[1]; // Малое число (2, 3, 4 и т.д.)
	} else {
		return wordForms[2]; // Множественное число
	}
}

export function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getIsMobile(width: number) {
	return width <= MOBILE_WIDTH;
}

export function stripTrailingSlash(str: string) {
	return str.endsWith('/') ? str.slice(0, -1) : str;
}
