import { FC, HTMLAttributes } from 'react';
import block from 'utils/bem-css-module';

import style from './modal.scss';


const b = block(style);

export const ModalButtons: FC<HTMLAttributes<HTMLElement>> = ({
	children,
	className = '',
	...rest
}) => {
	return (
		<footer className={b('buttons').mix(className)} {...rest}>
			{children}
		</footer>
	);
};
