import { FC, HTMLAttributes } from 'react';
import block from 'utils/bem-css-module';

import style from './paragraph.scss';

const b = block(style);
type Props = {
    size: 'xs' | 's' | 'sm' | 'm' | 'l' | 'xl';
}

export const Paragraph: FC<Props & HTMLAttributes<HTMLParagraphElement>> = ({
	size,
	className,
	children,
	...rest
}) => {
	return (
		<p 
			className={b({ size }).mix(className)} 
			{...rest}
		>
			{children}
		</p>
	);
};
