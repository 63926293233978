import { ChangeEvent, InputHTMLAttributes, ReactElement, useCallback } from 'react';
import block from 'utils/bem-css-module';

import style from './input.scss';

type Props = {
	onChange: ((e: ChangeEvent<HTMLInputElement>) => void) | (() => void);
	label?: string;
	onEnter?: ((e: KeyboardEvent) => void);
	id: string;
	containerClassname?: string;
} & InputHTMLAttributes<HTMLInputElement>

const b = block(style);


export const Input = ({
	type,
	label,
	value,
	onChange,
	onEnter,
	className,
	containerClassname,
	id,
	...rest
}: Props): ReactElement => {

	const onKeyDown = useCallback(function(e) {
		if (e.key === 'Enter') {
			onEnter && onEnter(e);
		}
	}, []);

	return (
		<div className={b().mix(containerClassname)}>
			{label && <label htmlFor={id} className={b('label')}>{label}</label>}
			<input
				type={type}
				className={b('input').mix(className)}
				value={value}
				onChange={onChange}
				onKeyDown={onKeyDown}
				id={id}
				{...rest}
			/>
		</div>
	);
};
