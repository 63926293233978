import { MAIN_ORIGIN, SB_ORIGIN } from 'constants/common';

import { Environment } from 'types/common';

export function getEnvironment() {
	if ([MAIN_ORIGIN, SB_ORIGIN].includes(window.location.host)) {
		return Environment.production;
	}
	if (process.env['NODE_ENV'] === Environment.development) {
		return Environment.development;
	}
	return Environment.testStand;
}
