import { createReduxEnhancer } from '@sentry/react';
import locationMiddleware from 'api/localMiddleware';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { Environment } from 'types/common';
import { State } from 'types/state';

import { createRootReducer } from './index';

const sentryReduxEnhancer = createReduxEnhancer({
	// Optionally pass options listed below
	// https://docs.sentry.io/platforms/javascript/guides/react/features/redux/
});

const composeEnhancers = (process.env.NODE_ENV !== Environment.production) && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function getStore(preloadedState: Partial<State> = {}) {
	return createStore(
		createRootReducer(),
		preloadedState,
		composeEnhancers(applyMiddleware(thunk, locationMiddleware), sentryReduxEnhancer),
	);
}
