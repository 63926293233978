import { FC, HTMLAttributes, ReactNode } from 'react';
import block from 'utils/bem-css-module';

import style from './alert.scss';
const b = block(style);

type Props = {
	children: ReactNode;
	type: 'info' | 'error' | 'success';
}

export const Alert: FC<Props & HTMLAttributes<HTMLDivElement>> = ({
	children, 
	className ,
	type,
	...rest
}) => {
	return (
		<div className={b({ type }).mix(className)} {...rest}>
			{children}
		</div>
	);
};
