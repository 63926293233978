import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { TYPE } from 'constants/action-types';
import { apiPath } from 'constants/api-path';

import { State } from 'types/state';

export function receivePoints(json) {
	return {
		type: TYPE.POINTS.RECEIVE,
		data: json,
	};
}

export function getPoints(): ThunkAction<void, State, never, Action> {
	return async (dispatch) => {
		try {
			const response = await fetch(`${apiPath.MAP_POINTS}`);
			if (!response.ok) {
				console.error('Can not receive map points');
				//@TODO add error handler
				return;
			}
			const points = await response.json();
			dispatch(receivePoints(points));
			// because it's the first response
			window.COORDS = {
				longitude: Number(response.headers.get('x-geoip-longitude')),
				latitude: Number(response.headers.get('x-geoip-latitude')),
			};
		} catch (e) {
			//@TODO add error handler
		}
	};
}

export default {
	receivePoints,
};
