import { forwardRef, HTMLAttributes, ReactNode } from 'react';
import block from 'utils/bem-css-module';

import style from './modal.scss';

type Props = {
    children?: ReactNode;
    className?: string;
	size: 's' | 'sm';
}

const b = block(style);

export const ModalContent = forwardRef<HTMLDivElement, Props & HTMLAttributes<HTMLElement>>(({
	children,
	className = '',
	id,
	size,
	...rest
}, ref) => {
	return (
		<div className={b('body', { size }).mix(className)} id={`${id}-body`} ref={ref} {...rest}>
			{children}
		</div>
	);
});
