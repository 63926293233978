import { DATE_OPTIONS } from 'constants/common';

import { AnyDocument, Document, DocumentStatus, ScanningDocument, ScanOperationStatus } from 'types/document';
import { ScanOperation } from 'types/operation';
import { State } from 'types/state';

export function getDocumentName(name: string): string {
	const newName = name.split('.').slice(0, -1);
	if (!newName || !newName.length) {
		return name;
	}
	return newName.join('.');
}

export function findDocumentByOperationId(documents: AnyDocument[], operation_id: number): Document | undefined {
	return documents.find((document) => (
		'operation_id' in document &&
		document.operation_id === operation_id
	)) as Document;
}

export function getDocumentDescription(document: Document | ScanningDocument) {
	const parsedTime = new Date(document.created_at).toLocaleString('ru-RU', DATE_OPTIONS);
	switch (document.status) {
		case ScanOperationStatus.converting:
		case ScanOperationStatus.convertWaiting:
			return 'Документ сохраняется...';
		case ScanOperationStatus.scanWaiting:
			return 'Ожидание начала сканирования';
		case ScanOperationStatus.scanning:
			return 'Идет сканирование';
		case ScanOperationStatus.successConverting:
			return 'Документ отсканирован';
		case ScanOperationStatus.failConverting:
			return `Не удалось отсканировать ${parsedTime}`;
		case DocumentStatus.error:
			return `Не удалось загрузить ${parsedTime} — ${document.pages_count} стр.`;
		case DocumentStatus.processed:
			return `Загружен ${parsedTime} — ${document.pages_count} стр.`;
		default:
			return 'Финальная обработка...';
	}
}

export function findDocumentByPk(documents: AnyDocument[], pk: number): Document | undefined {
	return documents.find((document) => (
		'pk' in document &&
		document.pk === pk
	)) as Document;
}

export function findDocumentByPks(documents: AnyDocument[], pks: number[]) {
	return documents.filter((document) => 'pk' in document && pks.includes(document.pk)) as Document[];
}

export function getIndexOfLastUploadingDocument(documents: AnyDocument[]): number {
	let indexOfUploadingDoc = -1;
	for (let i = 0; i < documents.length; i++) {
		if (!('pk' in documents[i])) {
			indexOfUploadingDoc = i;
		}
	}
	return indexOfUploadingDoc;
}

export function addDocument(documents: State['documents'], newDoc: AnyDocument) {
	const newDocuments = documents ? [...documents] : [];
	// doc is UploadingDocument
	if (('uploadingId' in newDoc)) {
		for (let i = 0; i < newDocuments.length; i++) {
			const doc = newDocuments[i];
			if ('uploadingId' in doc && doc.uploadingId === newDoc.uploadingId) {
				newDocuments[i] = newDoc;
				return newDocuments;
			}
		}
		newDocuments.unshift(newDoc);
		return newDocuments;
	}
	// doc is ScanningDocument
	if ('operation_id' in newDoc) {
		for (let i = 0; i < newDocuments.length; i++) {
			const doc = newDocuments[i];
			if ('operation_id' in doc && doc.operation_id === newDoc.operation_id) {
				newDocuments[i] = newDoc;
				return newDocuments;
			}
		}
		newDocuments.unshift(newDoc);
		return newDocuments;
	}
	for (let i = 0; i < newDocuments.length; i++) {
		const doc = newDocuments[i];
		if ('pk' in doc && doc.pk === newDoc.pk) {
			if (newDocuments[i].status !== DocumentStatus.processed) {
				newDocuments[i] = newDoc;
			}
			return newDocuments;
		}
	}
	const indexOfLastUploadingDocument = getIndexOfLastUploadingDocument(newDocuments);
	if (indexOfLastUploadingDocument !== -1) {
		newDocuments[indexOfLastUploadingDocument] = newDoc;
		return newDocuments;
	}
	newDocuments.unshift(newDoc);
	return newDocuments;
}

export function createScanDocument(operation: ScanOperation): ScanningDocument {
	return ({
		pk: operation.operation_document,
		name:operation.unique_name,
		created_at: operation.created_at,
		pdf_url: null,
		status_name: operation.status_name,
		status: operation.status,
		color_gs: 0,
		pages_count: 1,
		preview_warning: false,
		preview_size: 0,
		operation_id: operation.id,
	});
}

export function filterOutUploadingDocuments(documents: AnyDocument[]): (ScanningDocument | Document)[] {
	return documents.filter(doc => ('pk' in doc)) as (ScanningDocument | Document)[];
}

export function filterProcessedDocuments(documents: AnyDocument[] | never[]): Document[] {
	return documents.filter(doc => 'status' in doc && doc.status === DocumentStatus.processed) as Document[] | never[];
}

export function roundSizeInKb(bytes: number, round: 'up' | 'down'): number {
	const kb = bytes / 1024;
	if (kb < 10) {
		if (round === 'up') {
			return parseFloat(kb.toFixed(2));
		}
		return parseFloat(kb.toString().slice(0, 4));
	}
	if (round === 'up') {
		return Math.ceil(kb);
	}
	return Math.floor(kb);
}
