export const TYPE = {
	LOADING: {
		START: 'START_LOADING',
		END: 'END_LOADING',
	},
	CONVERSATION_MESSAGES: {
		SET:'CONVERSATION_MESSAGES_SET',
		SET_ONE: 'CONVERSATION_MESSAGES_SET_ONE',
	},
	ADMIN: {
		PRINTERS: {
			RECEIVE: 'ADMIN_PRINTERS_RECEIVE',
			REQUEST: 'ADMIN_PRINTERS_REQUEST',
			ADD_ERROR: 'ADD_ERROR_TO_ADMIN_PRINTERS',
			STATISTIC: {
				RECEIVE: 'ADMIN_PRINTERS_STATISTIC_RECEIVE',
				CHANGE_OPTIONS: 'ADMIN_CHANGE_OPTIONS_PRINTERS_STATISTIC',
			},
		},
		SCANNERS: {
			RECEIVE: 'ADMIN_SCANNERS_RECEIVE',
			REQUEST: 'ADMIN_SCANNERS_REQUEST',
			ADD_ERROR: 'ADD_ERROR_TO_ADMIN_SCANNERS',
		},
	},
	STATISTIC: {
		REQUEST: 'REQUEST_STATISTIC',
		RECEIVE: 'RECEIVE_STATISTIC',
		SELECT: 'SELECT_STATISTIC',
		CHANGE_OPTIONS: 'CHANGE_OPTIONS_STATISTIC',
		ADD_ERROR: 'ADD_ERROR_TO_STATISTIC',
	},
	QUESTIONS: {
		REQUEST: 'REQUEST_QUESTIONS',
		RECEIVE: 'RECEIVE_QUESTIONS',
		ADD: 'ADD_QUESTION',
		ADD_ERROR: 'ADD_ERROR_TO_QUESTIONS',
	},
	PRINTERS: {
		REQUEST: 'REQUEST_PRINTERS',
		RECEIVE: 'RECEIVE_PRINTERS',
		CHANGE_OPTIONS: 'CHANGE_OPTIONS_PRINTERS',
		ADD_ERROR: 'ADD_ERROR_TO_PRINTERS',
		ADD_ERROR_TO_PRINTER: 'ADD_ERROR_TO_PRINTER',
	},
	POINTS: {
		REQUEST: 'REQUEST_POINTS',
		RECEIVE: 'RECEIVE_POINTS',
		SET_NEAREST_POINTS: 'SET_NEAREST_POINTS',
	},
	SCANNERS: {
		REQUEST: 'REQUEST_SCANNERS',
		RECEIVE: 'RECEIVE_SCANNERS',
		CHANGE_OPTIONS: 'CHANGE_OPTIONS_SCANNERS',
		CREATE_OPERATION: 'CREATE_SCANNING_OPERATION',
		RECEIVE_OPERATIONS: 'RECEIVE_SCANNING_OPERATIONS',
		REQUEST_OPERATIONS: 'REQUEST_SCANNING_OPERATIONS',
		ADD_ERROR_TO_SCANNER: 'ADD_ERROR_TO_SCANNER',
	},
	PRINT: {
		CHANGE_OPTIONS: 'PRINT_CHANGE_OPTIONS',
	},
	USER: {
		RECEIVE: 'RECEIVE_USER',
		CHANGE_FAVORITE_PRINTER: 'USER_FAVORITE_PRINTER',
		CHANGE_FAVORITE_SCANNER: 'USER_FAVORITE_SCANNER',
		CHANGE: 'USER_CHANGE',
		CHANGEBACK: 'USER_CHANGE_BACK',
		SET_COLOR_THEME: 'USER_SET_COLOR_THEME',
		ORDERS: {
			GET: 'GET_USER_ORDERS',
			RECEIVE: 'RECEIVE_USER_ORDERS',
		},
	},
	DOCUMENTS: {
		GET: 'DOCUMENTS_GET',
		SET: 'DOCUMENTS_SET',
		RECEIVE: 'DOCUMENTS_RECEIVE',
		DELETE: 'DOCUMENT_DELETE',
		DELETE_SCAN_DOCUMENT: 'DELETE_SCAN_DOCUMENT',
		ADD: 'DOCUMENT_ADD',
	},
} as const;

Object.freeze(TYPE);
