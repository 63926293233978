import { TYPE } from 'constants/action-types';

export default function (state = {}, action) {
	switch (action.type) {
		case TYPE.ADMIN.PRINTERS.RECEIVE: {
			return {
				status: {},
				data: action.data,
			};
		}
		case TYPE.PRINTERS.ADD_ERROR_TO_PRINTER: {
			const newState = { ...state };
			newState.status = {
				message: action.printer.message,
				pk: action.printer.pk,
			};
			return newState;
		}
		default: {
			return state;
		}
	}
}
