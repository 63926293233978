import { getDateOneMonthAgo } from 'utils/date-format';

import { TYPE } from 'constants/action-types';

import { State } from 'types/state';

export default function (state: State['statisticPrinters'] = null, action: any) {
	switch (action.type) {
		case TYPE.ADMIN.PRINTERS.STATISTIC.RECEIVE: {
			const newState = { 
				data: {},
				point: -1,
				startDate: getDateOneMonthAgo(new Date()),
				endDate: new Date(),
				...(state || {}), 
			};
			newState.data[action.printer] = action.data;
			return newState;
		}
		case TYPE.ADMIN.PRINTERS.STATISTIC.CHANGE_OPTIONS: {
			return {
				...state,
				...action.options,
			};
		}
		default: {
			return state;
		}
	}
}
