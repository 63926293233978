import { Middleware } from 'redux';
import { post } from 'utils/fetch';

import actions from 'actions';
import { TYPE } from 'constants/action-types';
import { apiPath } from 'constants/api-path';

import { State } from 'types/state';

export const localMiddleware: Middleware<void, State> = (store) => (next) => (action) => {
	switch (action.type) {
		case TYPE.USER.CHANGE_FAVORITE_SCANNER: {
			(async (): Promise<void> => {
				await post(apiPath.FAVORITE_SCANNER, action.data);
				store.dispatch(actions.user.change(action.data));

			})();
			break;
		}
	}
	return next(action);
};

export default localMiddleware;
