import { combineReducers } from 'redux';

import adminPrinters from './adminPrinters';
import { conversationMessages } from './conversationMessages';
import documents from './documents';
import points from './points';
import print from './print';
import printers from './printers';
import statisticPrinters from './statisticPrinters';
import user from './user';

export const createRootReducer = () => combineReducers({
	conversationMessages,
	adminPrinters,
	statisticPrinters,
	documents,
	printers,
	points,
	print,
	user,
});
