import { TYPE } from '../constants/action-types';

export default function (state = [], action) {
	switch (action.type) {
		case TYPE.PRINTERS.RECEIVE: {
			return {
				status: {},
				data: action.data,
			};
		}
		default: {
			return state;
		}
	}
}
