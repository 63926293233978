import { FC } from 'react';
import block from 'utils/bem-css-module';

import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';
import { MOBILE_WIDTH } from 'constants/common';
import { useScreenSize } from 'hooks/use-screen-size';

import style from './error-page.scss';

const b = block(style);

export const ErrorPage: FC = () => {
	const { width } = useScreenSize();

	const reload = () => {
		window.location.reload();
	};

	const toHome = () => {
		window.location.href = '/';
	};

	return (
		<section className={b()}>
			<div className={b('container')}>
				<img
					width={width > MOBILE_WIDTH ? 160 : 100}
					height={width > MOBILE_WIDTH ? 160 : 100}
					alt='error icon'
					className={b('icon')}
					src='/static/img/copy-doc.svg'
				/>
				<Heading size='l' level={1} className={b('heading')}>Что-то пошло не так...</Heading>
				<Paragraph size='sm'>
					Извините, на странице произошла ошибка, мы уже знаем об этом и стараемся её исправить.
				</Paragraph>
				<div className={b('button-group')}>
					<Button
						buttonType='warning'
						onClick={reload}
						size='m'
						className={b('button')}
					>
						Обновить страницу
					</Button>
					<Button
						buttonType='default'
						onClick={toHome}
						to='/'
						size='m'
						className={b('button')}
					>
						На главную
					</Button>
				</div>
			</div>
		</section>
	);
};
