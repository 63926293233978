import { TYPE } from 'constants/action-types';

import { User } from 'types/user';

export default function (state: User | null = null, action: any): User | null {
	switch (action.type) {
		case TYPE.USER.RECEIVE: {
			return {
				...state,
				...action.data,
			};
		}
		case TYPE.USER.CHANGE: {
			return { ...state, ...action.data };
		}
		default: {
			return state;
		}
	}
}
