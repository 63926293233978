import { ButtonHTMLAttributes, FC } from 'react';
import { NavLink } from 'react-router-dom';
import block from 'utils/bem-css-module';
import { getDocumentName } from 'utils/document';

import { Document } from 'types/document';

import style from './button.scss';

type Props = {
	buttonType: 'success' | 'warning' | 'empty' | 'danger' | 'info' | 'default' | 'link' | 'nav-link';
	size?: 'xs' | 's' | 'sm' | 'm';
	loading?: boolean;
	rounded?: boolean;
	onChange?: (e: any) => Promise<void> | void;
	document?: Document;
	to?: string;
	icon?: string;
}

const b = block(style);

export const Button: FC<Props & ButtonHTMLAttributes<HTMLButtonElement>> = ({
	onClick,
	onChange,
	className = '',
	disabled = false,
	children,
	loading = false,
	rounded = false,
	buttonType,
	to,
	icon,
	document,
	size = 's',
	...rest
}) => {
	if (buttonType === 'nav-link' && to) {
		return (
			<NavLink
				className={b({ type: 'default', rounded, disabled, size }).mix(className)}
				to={to}
				onClick={onChange}
			>
				{children}{icon && <span className={b('icon')} style={{ maskImage: `url(${icon})` }} />}
			</NavLink>
		);
	}
	if (buttonType === 'link') {
		return (
			<a className={b({ type: 'default', rounded }).mix(className)}
			   download={`${getDocumentName(document?.name || 'документ_printbox')}.pdf`}
			   href={document?.pdf_url || undefined}
			>
				{children}{icon && <span className={b('icon')} style={{ maskImage: `url(${icon})` }} />}
			</a>
		);
	}
	return (
		<button
			className={b({ type: buttonType, loading, rounded, size }).mix(className)}
			onClick={onClick}
			disabled={disabled || loading}
			{...rest}
		>
			{children}{icon && <span className={b('icon')} style={{ maskImage: `url(${icon})` }} />}
		</button>
	);
};
