import { FC, InputHTMLAttributes } from 'react';
import block from 'utils/bem-css-module';

import { YANDEX_METRIC_ID } from 'constants/common';

import style from './file-button.scss';


type Props = {
	buttonType?: 'success' | 'warning' | 'danger' | 'info' | 'default';
	loading?: boolean;
    allowedMimeTypes?: string;
	id: string;
	size?: 's' | 'm';
	ymEventName: string;
}

const b = block(style);

export const FileButton: FC<Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> & Props> = ({
	className,
	disabled = false,
	children,
	loading = false,
	allowedMimeTypes = '*',
	size = 's',
	buttonType = 'default',
	id,
	ymEventName,
	...rest
}) => {

	const onFileButtonClick = () => {
		ym(YANDEX_METRIC_ID, 'reachGoal', ymEventName); // Нажатие на кнопку загрузки файла (или область drag-n-drop)
	};

	return (
		<>
			<label
				htmlFor={id}
				className={b({ type: buttonType, loading, disabled, size }).mix(className)}
			>
				{children}
			</label>
			<input
				type='file'
				className={b('input')}
				id={id}
				accept={allowedMimeTypes}
				onClick={onFileButtonClick}
				{...rest}
			/>
		</>
	);
};
