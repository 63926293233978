import { forwardRef } from 'react';
import block from 'utils/bem-css-module';

import style from './modal.scss';

const b = block(style);

type Props = {
	onClick: () => void;
	isOpen: boolean;
}

export const ModalCloseButton = forwardRef<HTMLButtonElement, Props>(({
	onClick,
	isOpen,
}, ref) => (
	<button
		className={b('close')}
		onClick={onClick}
		ref={ref}
		aria-label='закрыть'
		disabled={!isOpen}
	/>
));
