import { init } from '@sentry/react';
import { getEnvironment } from 'utils/settings';

export function initSentry() {
	init({
		environment: getEnvironment(),
		dsn: 'https://1efed7ed544a4900d29efb62b43e27b0@o4506970922418176.ingest.us.sentry.io/4506970927595520',
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ['localhost', /^https:\/\/printbox\.io\/api/],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}
